<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>创建任务</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <div v-for="(item, index) in form" :key="index" class="form-item">
          <a-form-model
            :model="item"
            :rules="rules"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            ref="ruleForm"
            style="padding: 16px"
          >
            <a-form-model-item label="任务名称" prop="taskName">
              <a-input
                v-model="item.taskName"
                class="aft-input drawer"
                placeholder="请输入任务名称, 如: 直播场次达到6次"
                style="width: 480px; height: 40px"
                :maxLength="15"
              />
            </a-form-model-item>
            <a-form-model-item prop="targetValue">
              <span slot="label">
                <span style="margin-right: 8px;">任务目标</span>
                <AftTooltip code="hdzb05" />
              </span>
              <a-input
                v-model="item.targetValue"
                class="aft-input drawer"
                placeholder="请输入数值"
                style="width: 100%;"
              >
                <a-select
                  slot="addonBefore"
                  v-model="item.taskType"
                  placeholder="请选择维度"
                  style="width: 200px;"
                >
                  <a-select-option
                    v-for="item in taskTypeList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-input>
              <span class="warning-tip" v-if="!onlyShipinhao && hasShipinhao"
                >视频号暂不支持模板</span
              >
              <!-- <div class="label-text">
                比如维度选择【视频数】，数值输入【5】，则参与门店在活动期间发布
                <br />超过 5 条视频才算完成任务
              </div> -->
            </a-form-model-item>
            <a-form-model-item label="任务说明" prop="taskExplain">
              <a-input
                v-model="item.taskExplain"
                type="textarea"
                class="aft-input drawer"
                placeholder="请输入任务说明"
                style="width: 480px;"
                :maxLength="50"
                :autoSize="{ minRows: 3, maxRows: 3 }"
              />
            </a-form-model-item>
          </a-form-model>
          <div class="delete-button" @click="deleteTask(index)">
            <svg-icon icon-class="delete" />
            <span>删除</span>
          </div>
        </div>
        <div class="add-button" @click="addTask">
          <a-icon type="plus" />
          <span>新增任务</span>
        </div>
      </div>
      <div class="drawer-tools">
        <span class="btn btn1" @click="onClose">取消</span>
        <a-button
          class="btn btn2"
          @click="drawerSubmit"
          :loading="isAddGroupLoading"
          >确定</a-button
        >
      </div>
    </div>
    <a-modal
      v-model="deleteModal"
      :closable="false"
      :footer="null"
      dialogClass="modal-class"
    >
      <div class="modal-container">
        <a-icon type="exclamation-circle" theme="filled" class="icon" />
        <span>内容尚未保存，确认要中止创建吗？</span>
      </div>
      <div class="modal-footer">
        <div class="btn btn1" @click="deleteModal = false">取消</div>
        <div class="btn btn2" @click="sureDelete">确认</div>
      </div>
    </a-modal>
  </a-drawer>
</template>

<script>
import api from "@/api/taskCenter.js";
import deepClone from "@/utils/clone-utils";
import { getPrincipalId } from "@/utils/auth";

const defaultTaskTypeList = [
  { label: "视频发布数", value: "1" },
  { label: "播放≥500视频数", value: "6" },
  { label: "活动模版视频数", value: "5" },
  { label: "播放≥500活动模板视频数", value: "8" },
  { label: "任意模版视频数", value: "12" },
  { label: "播放≥500任意模板视频数", value: "13" },
  { label: "累计视频播放量", value: "3" },
  { label: "直播场次", value: "2" },
  { label: "时长≥30min直播场次", value: "7" },
  { label: "时长≥60min直播场次", value: "9" },
  { label: "时长≥90min直播场次", value: "10" },
  { label: "时长≥120min直播场次", value: "11" },
  { label: "直播观看量", value: "4" },
  { label: "笔记发布数", value: "14" },
];
const defaultFormItem = {
  taskName: "",
  taskType: "1",
  targetValue: "",
  taskExplain: "",
};

export default {
  props: {
    activity: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentActivityId: "",
      visible: false,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      formItemLayout: {
        labelCol: { span: 24 },
        wrapperCol: { span: 18 },
      },
      form: [deepClone(defaultFormItem)],
      rules: {
        taskName: {
          required: true,
          message: "请输入任务名称",
          whitespace: true,
        },
        targetValue: [
          {
            required: true,
            message: "请选择并填写任务目标",
            whitespace: true,
          },
          {
            message: "请输入一个大于0的整数",
            pattern: new RegExp(/^[1-9]\d*$/, "g"),
          },
        ],
      },
      taskTypeList: [],
      isAddGroupLoading: false,
      allBool: true,
      deleteModal: false,
      currentDeleteIndex: "",
    };
  },
  computed: {
    onlyShipinhao() {
      return this.activity.platforms === "4";
    },
    hasShipinhao() {
      const platforms = this.activity.platforms.split(",");
      return platforms.includes("4");
    },
  },
  methods: {
    showDrawer(id) {
      // 赋值活动ID
      this.currentActivityId = id;
      this.setTaskTypeList();
      // 重置form
      this.form = [
        {
          ...deepClone(defaultFormItem),
          taskType: this.taskTypeList[0].value,
        },
      ];
      // 打开弹窗
      this.visible = true;
    },

    setTaskTypeList() {
      // 视频号去掉 活动模版视频数、播放≥500活动模板视频数、任意模版视频数、播放≥500任意模板视频数
      let taskTypeList = deepClone(
        defaultTaskTypeList.filter((item) => {
          return this.onlyShipinhao
            ? !["5", "12", "13", "8"].includes(item.value)
            : true;
        })
      );

      let values = [];

      if (this.activity.isLive === 1) {
        const liveValues = ["2", "7", "9", "10", "11", "4"];
        values = [...values, ...liveValues];
      }

      if (this.activity.isVideo === 1) {
        let liveValues = [];
        //0全部视频, 1仅使用活动导拍模版  2使用任意模版
        switch (`${this.activity.videoCreateSource}`) {
          case "0":
            liveValues = ["1", "6", "5", "8", "12", "13", "3"];
            break;
          case "1":
            liveValues = ["1", "6", "3"];
            break;
          case "2":
            liveValues = ["1", "6", "3"];
            break;
          default:
            break;
        }
        values = [...values, ...liveValues];
      }
      if (this.activity.isNote === 1) {
        values = ["14", ...values];
      }
      taskTypeList = taskTypeList.filter((item) => {
        return values.includes(item.value);
      });
      this.taskTypeList = taskTypeList;
    },

    // 活动弹窗-取消
    onClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.forEach((item) => {
          item.resetFields();
        });
      });
      this.visible = false;
    },
    // 新增任务
    addTask() {
      this.form.push({
        ...deepClone(defaultFormItem),
        taskType: this.taskTypeList[0].value,
      });
    },
    // 删除任务
    deleteTask(index) {
      if (this.form.length <= 1) {
        this.$message.warning("至少保留一个任务！");
        return false;
      }
      this.deleteModal = true;
      this.currentDeleteIndex = index;
    },
    sureDelete() {
      this.form.splice(this.currentDeleteIndex, 1);
      this.deleteModal = false;
    },
    // 活动弹窗-确定
    async drawerSubmit() {
      this.allBool = true;
      this.$refs.ruleForm.forEach((item) => {
        item.validate((valid) => {
          if (valid) {
          } else {
            this.allBool = false;
          }
        });
      });
      if (this.allBool) {
        console.log("this.form", this.form);
      } else {
        this.$message.error("校验不通过,无法提交");
        return false;
      }
      this.isAddGroupLoading = true;
      let body = this.form.map((item) => {
        return {
          ...item,
          activityId: this.currentActivityId,
          principalId: getPrincipalId(),
        };
      });
      console.log(body);
      api
        .createTask(body)
        .then((res) => {
          console.log("createTask", res);
          if (res.data.code === 200) {
            this.$message.success("提交成功");
            this.onClose();
            this.$emit("getList");
          } else {
            this.$message.error(res.data.message || res.message || "提交失败");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isAddGroupLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    overflow-x: hidden;
    .form-item {
      width: 512px;
      // height: 386px;
      border-radius: 8px;
      border: 1px solid #323744;
      margin-bottom: 16px;
      position: relative;
      &:first-child {
        margin-top: 24px;
      }
      .delete-button {
        width: 100%;
        height: 48px;
        border-top: 1px solid #323744;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 0.88);
        cursor: pointer;
        > span {
          margin-left: 10px;
        }
        &:hover {
          color: #ffcc55;
        }
      }
    }
    .add-button {
      width: 512px;
      height: 40px;
      border-radius: 6px;
      border: 1px dashed rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.88);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 24px;
      > span {
        margin-left: 8px;
      }
      &:hover {
        color: #ffcc55;
      }
    }
    .label-text {
      font-size: 14px;
      color: #545a68;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}

.modal-container {
  display: flex;
  align-items: center;
  height: 24px;
  .icon {
    font-size: 21px;
    color: #ff9026;

    margin-right: 17px;
  }
  > span {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
  }
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  .btn {
    height: 32px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .btn1 {
    width: 74px;
    background: #2b2f3b;
    color: rgba(255, 255, 255, 0.65);
  }
  .btn2 {
    width: 65px;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
    margin-left: 8px;
  }
}
</style>

<style lang="scss" scoped>
/deep/ .modal-class {
  width: 360px;
  height: 136px;
  background: #1a1d26;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.32),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 12px 48px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .ant-modal-body {
    width: 100%;
    height: 100%;
    padding: 32px 32px 24px 34px !important;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: #1a1d26 !important;
    box-shadow: none !important;
  }
}

.warning-tip {
  color: #ff9026;
}
</style>
